.ranking-container {
  display: flex;
  .ranking-details {
    margin: 0 auto 50px;
    width: 75%;
    border-radius: 8px;
  }
  .calculation {
    background: var(--white);
    border-radius: 8px;
    margin-top: 8px;
    display: block;
    width: 100%;
    padding: 40px;
    color: var(--primary-n40);
    .question {
      font-size: 24px;
      //   font-size: 1.5rem;
      line-height: 32px;
    }
    .answer {
      margin-top: 16px;
      margin-bottom: 40px;
      max-width: 624px;
      font-size: 16px;
      //   font-size: 0.875rem;
      line-height: 22px;
      ul {
        list-style: lower-roman;
      }
      a {
        font-weight: bold;
        color: var(--primary-b20);
      }
    }
  }
  .ranking {
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 66px;
    background: var(--white);
    .header {
      width: 100%;
      font-size: 24px;
      //   font-size: 1.5rem;
      line-height: 32px;
      color: var(--primary-n40);
    }
    .priority-wrap {
      writing-mode: vertical-lr;
      text-orientation: mixed;
      text-transform: uppercase;
      transform: rotate(-180deg);
      margin-bottom: 75px;
      .p-priority {
        display: flex;
        flex-wrap: wrap;
        height: 384px;
        p {
          padding: 0 25px 0 8px;
          margin: 0;
          height: 33.33%;
          text-align: center;
          font-size: 12px;
          //   font-size: 0.625rem;
          line-height: 18px;
          color: var(--tint-shade-n10);
        }
      }
      .plabel {
        font-size: 14px;
        // font-size: 0.75rem;
        line-height: 20px;
        text-align: left;
      }
    }
    .readiness-wrap {
      text-align: center;
      text-transform: uppercase;
      .project-readiness {
        display: flex;
        flex-wrap: wrap;
        p {
          width: 33.33%;
          font-size: 12px;
          //   font-size: 0.625rem;
          line-height: 18px;
          margin-top: 10px;
          margin-bottom: 25px;
          color: var(--tint-shade-n10);
        }
      }
      .plabel {
        font-size: 14px;
        // font-size: 0.75rem;
        line-height: 20px;
        text-align: left;
        &.ongoing{
          background-position: center left 215px;
        }
      }
    }
    &__cards {
      width: 85%;
      p.hint {
        font-size: 14px;
        // font-size: 0.75rem;
        line-height: 20px;
        margin-top: 57px;
        margin-bottom: 28px;
        color: var(--tint-shade-n20);
      }
      ul {
        padding: 0;
        margin: auto;
        background: var(--white);
        height: 384px;
        // width:828px;
        width: 100%;
        position: relative;
        .rank-card {
          width: 32%;
          height: 120px;
          padding: 16px;
          transition: all 0.1s ease-in;
          position: absolute;
          z-index: 100;
          border-radius: 6px;
          &.A {
            background: var(--tint-shade-p40);
            top: 0px;
            right: 0px;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-p40);
            .grade,
            .program-no {
              color: var(--white);
            }
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-p40);
            }
          }
          &.A- {
            background: var(--tint-shade-p30);
            top: 132px;
            right: 0;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-p30);
            .grade,
            .program-no {
              color: var(--white);
            }
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-p30);
            }
          }
          &.B {
            background: var(--primary-b20);
            top: 0px;
            // right: 280px;
            right: 34%;
            color: var(--white);
            box-shadow: 0px 12px 24px -8px var(--primary-b20);
            .grade,
            .program-no {
              color: var(--white);
            }
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--primary-b20);
            }
          }
          &.B- {
            background: var(--tint-shade-b10);
            top: 132px;
            // right: 280px;
            right: 34%;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-b10);
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-b10);
            }
          }
          &.C {
            background: var(--tint-shade-p10);
            top: 0px;
            left: 0px;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-p10);
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-p10);
            }
          }
          &.C- {
            background: var(--tint-shade-b5);
            top: 132px;
            left: 0px;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-b5);
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-b5);
            }
          }
          &.D {
            background: var(--tint-shade-t30);
            bottom: 0px;
            right: 0px;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-t30);
            .grade,
            .program-no {
              color: var(--white);
            }
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-t30);
            }
          }
          &.D- {
            background: var(--tint-shade-t10);
            bottom: 0px;
            // right: 280px;
            right: 34%;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-t10);
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-t10);
            }
          }
          &.E {
            background: var(--tint-shade-t5);
            bottom: 0px;
            left: 0px;
            box-shadow: 0px 12px 24px -8px var(--tint-shade-t5);
            &.have-link:hover {
              transform: scale(1.05);
              z-index: 110;
              box-shadow: 0px 10px 34px 0px var(--tint-shade-t5);
            }
          }

          .grade {
            display: block;
            font-size: 24px;
            // font-size: 1.5rem;
            line-height: 32px;
            font-weight: bold;
            color: var(--primary-n40);
          }
          .program-no {
            display: block;
            text-align: center;
            font-size: 38px;
            // font-size: 2.375rem;
            line-height: 1.1;
            font-weight: bold;
            color: var(--primary-n40);
            margin: 0;

            span {
              display: block;
              text-transform: uppercase;
              font-size: 14px;
              //   font-size: 0.75rem;
              font-weight: normal;
            }
          }
          &.have-link {
            cursor: pointer;
          }
        }
      }
    }
  }
  .ranking-dashboard {
    width: 25%;
    margin: 0 auto;
    font-weight: bold;
    font-size: 14px;
    // font-size: 0.75rem;
    line-height: 20px;
    color: var(--primary-n40);
    padding: 40px 0 20px 20px;
    .title {
      margin-bottom: 20px;
      padding-left: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        div {
          display: inline-flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          //   font-size: 0.75rem;
          line-height: 20px;
          color: var(--primary-n40);
          margin-bottom: 20px;
          &:before {
            content: "";
            display: inline-block;
            height: 8px;
            width: 8px;
            min-width: 8px;
            border-radius: 50%;
            margin-right: 10px;
          }
          &:after {
            font-weight: normal;
            display: inline;
            padding-left: 12px;
          }
        }
        &.A div {
          &:before {
            background-color: var(--tint-shade-p40);
          }
          &:after {
            content: "High project readiness and high ranking";
          }
        }
        &.A- div {
          &:before {
            background-color: var(--tint-shade-p30);
          }
          &:after {
            content: "High project readiness and medium ranking";
          }
        }
        &.B div {
          &:before {
            background-color: var(--primary-b20);
          }
          &:after {
            content: "Medium project readiness and high ranking";
          }
        }
        &.B- div {
          &:before {
            background-color: var(--tint-shade-b10);
          }
          &:after {
            content: "Medium project readiness and medium ranking";
          }
        }
        &.C div {
          &:before {
            background-color: var(--tint-shade-p10);
          }
          &:after {
            content: "Low project readiness and high ranking";
          }
        }
        &.C- div {
          &:before {
            background-color: var(--tint-shade-b5);
          }
          &:after {
            content: "Low project readiness and medium ranking";
          }
        }
        &.D div {
          &:before {
            background-color: var(--tint-shade-t30);
          }
          &:after {
            content: "High project readiness and low ranking";
          }
        }
        &.D- div {
          &:before {
            background-color: var(--tint-shade-t10);
          }
          &:after {
            content: "Medium project readiness and low ranking";
          }
        }
        &.E div {
          &:before {
            background-color: var(--tint-shade-t5);
          }
          &:after {
            content: "Low project readiness and low ranking";
          }
        }
        &.A.ongoing div {
          &:after {
            content: "High project implementation and high ranking";
          }
        }
        &.A-.ongoing div {
          &:after {
            content: "High project implementation and medium ranking";
          }
        }
        &.B.ongoing div {
          &:after {
            content: "Medium project implementation and high ranking";
          }
        }
        &.B-.ongoing div {
          &:after {
            content: "Medium project implementation and medium ranking";
          }
        }
        &.C.ongoing div {
          &:after {
            content: "Low project implementation and high ranking";
          }
        }
        &.C-.ongoing div {
          &:after {
            content: "Low project implementation and medium ranking";
          }
        }
        &.D.ongoing div {
          &:after {
            content: "High project implementation and low ranking";
          }
        }
        &.D-.ongoing div {
          &:after {
            content: "Medium project implementation and low ranking";
          }
        }
        &.E.ongoing div {
          &:after {
            content: "Low project implementation and low ranking";
          }
        }
      }
    }
  }
}
