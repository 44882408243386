.redux-toastr {
    .toastr {
        min-height: 50px;

        .rrt-left-container {
            width: 50px;

            .rrt-holder {
                width: 50px;
                height: 50px;
            }

            .toastr-icon {
                width: 20px !important;
                height: 20px !important;
            }
        }

        .close-toastr {
            color: #fff;
        }
    }
}

.nepali-date-picker .drop-down-content{
    min-width: 70px;

    li{
        text-align: left;
    }
}