.bold{
    font-weight:bold;
}

.normal{
    font-weight: normal;
}

.heading-1{
    font-size:var(--heading-1);
    line-height:100%;
}

.heading-2{
    font-size:var(--heading-2);
    line-height:100%;
}

.body-1{
    font-size:var(--body-1);
    line-height:100%;
}

.body-2{
    font-size:var(--body-2);
    line-height:100%;
}

.label-1{
    font-size:var(--label-1);
    line-height:100%;
}

.label-2{
    font-size:var(--label-2);
    line-height:100%;
}