.trash-bulk-operation {
  p {
    margin: 0 0 6px 0;
  }

  .btn-outline {
    margin-right: 10px;
  }
}

.table-container {
  position: relative;
  min-height: 400px;
  margin-top: 30px;
}

.assessment {
  background: var(--tint-shade-g0);
  border: 1px solid var(--tint-shade-g5);
  border-radius: 4px;
  //   padding: 20px;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__heading {
    padding: 20px;
    cursor: pointer;

    .heading {
      &-title {
        margin-bottom: 0;
        &::before {
          content: '';
          border-top: 3px solid transparent;
          display: inline-block;
          border-bottom: 3px solid transparent;
          border-left: 5px solid var(--primary-n40);
          margin-right: 10px;
          vertical-align: middle;
          transition: transform 0.3s ease;
        }
      }

      &-date {
        color: var(--tint-shade-n10);

        span {
          color: var(--tint-shade-n20);
        }
      }
    }

    &.expand {
      .heading-title::before {
        transform: rotate(90deg);
      }
    }
  }

  &__items {
    padding: 30px 20px 15px;
    background: rgba(180, 228, 158, 0.2);
    border-bottom: 1px solid var(--tint-shade-g5);

    &:last-child {
      border-bottom: none;
    }

    .assessed-on {
      margin: 0 0 0 10px;
    }

    .ui.grid {
      .row {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 20px;
        }

        .label {
          color: var(--tint-shade-n20);
        }
      }
    }
  }

  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.print-cont {
  display: flex;
  flex-direction: row-reverse;
  background: rgba(180, 228, 158, 0.2);
  padding: 15px 20px 0px;
}
.project-detail-container.printable{
  @media print {
    .project-section{
      display: none;
    }
    #quarterly-assessments{
      display:block!important;           
    }
  }
}

.qassessment {
  background: var(--tint-shade-g0);
  border: 1px solid var(--tint-shade-g5);
  border-radius: 4px;
  //   padding: 20px;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  &__heading {
    padding: 20px;
    cursor: pointer;

    .heading {
      &-title {
        margin-bottom: 0;
        &::before {
          content: '';
          border-top: 3px solid transparent;
          display: inline-block;
          border-bottom: 3px solid transparent;
          border-left: 5px solid var(--primary-n40);
          margin-right: 10px;
          vertical-align: middle;
          transition: transform 0.3s ease;
        }
      }

      &-date {
        color: var(--tint-shade-n10);

        span {
          color: var(--tint-shade-n20);
        }
      }
    }

    &.expand {
      .heading-title::before {
        transform: rotate(90deg);
      }
    }
  }

  &__qitems {
    padding: 15px 20px 15px;
    background: rgba(180, 228, 158, 0.2);
    border-bottom: 1px solid var(--tint-shade-g5);

    &:last-child {
      border-bottom: none;
    }
    .submission-cont {
      display: flex;
      justify-content: space-between;
      .assessed-on {
        margin: 0 0 0 10px;
      }
    }
    .files-cont {
      font-weight: normal;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 0;
    }

    .grid-cont {
      padding: 10px 0px !important;
    }

    .ui.grid {
      .row {
        padding-bottom: 5px;
        margin-bottom: 8px;
        // &:not(:last-child) {
        //   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        //   margin-bottom: 20px;
        // }

        .label {
          color: var(--tint-shade-n20);
        }
        .label-heading {
          color: var(--tint-shade-n20);
          font-style: bold;
          font-weight: 700;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          margin-top: 10px;
          width: 100%;
          padding-top: 8px;
        }
        .label-content {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__qitem {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
