.ui.input {
  color: var(--primary-n40);
  border: 1px solid var(--primary-b20);
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  //   font-size: 0.875rem;
  background: var(--white);

  input {
    outline: none;
    border: none;
    margin: 3px;

    &::placeholder {
      color: var(--tint-shade-n10);
    }
  }

  &.action {
	> .button {
		background: var(--tint-shade-b20);
		margin: 3px;
		color: var(--white);
    border-radius: 4px !important;
    text-transform: capitalize;
    // visibility: hidden;
    // display: none;
	}

	// input{
  //   &:focus{
  //     ~ .button{
  //         display: block;
  //       }
  //     }
  //   }
  }
}

.tag {
  display: inline-block;
  padding: 8px 12px;
  color: var(--primary-n40);
  background: var(--tint-shade-n5);
  border-radius: 4px;
  font-size: 14px;
  //   font-size: 0.75rem;
  text-transform: capitalize;

  i.icon {
    cursor: pointer !important;
  }
}
