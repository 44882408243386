.header {
  color: var(--primary-n40);
  &.large {
    // text-transform: uppercase;
    margin-top: 37px;
    font-size: 24px;
    // font-size: 1.5rem;
    line-height: 32px;
  }
  &.medium {
    margin-bottom: 27px;
    font-size: 24px;
    // font-size: 1.5rem;
    line-height: 32px;
  }
  &.small {
    font-size: 18px;
    // font-size: 1.125rem;
    line-height: 26px;
  }
  &.tiny {
    font-size: 18px;
    // font-size: 1rem;
    line-height: 24px;
  }
}
.project-detail {
  display: flex;
  &-container {
    width: 75%;
    @media print {
      width: 100%;
    }
    .project-section {
      .ui.attached.segment {
        border: 0;
        padding: 0;
        .row {
          font-size: 16px;
          //   font-size: 0.875rem;
          line-height: 22px;
          &:not(:last-child) {
            margin-bottom: 11px;
            border-bottom: 1px solid var(--primary-b0);
          }
          &:first-child {
            border-top: 0;
          }
          .five {
            p {
              color: var(--tint-shade-n20);
            }
          }
          .project-info {
            color: var(--primary-n40);
            font-weight: normal;
          }
        }
      }
      .row + .ui.divider {
        background: var(----tint-shade-b5);
      }
    }
  }
  &-dashboard {
    width: 25%;
    padding: 20px;
    margin-top: 60px;
    ul {
      padding: 0;
      margin: 0;
      position: sticky;
      top: 20px;
      li {
        margin: 0;
        padding: 10px 20px;
        font-size: 14px;
        // font-size: 0.75rem;
        line-height: 140%;
        color: var(--primary-n40);
        position: relative;
        &:not(:last-child) {
          border-bottom: 1px solid var(--tint-shade-b5--20);
        }

        a, p {
          color: var(--tint-shade-n30);
          font-weight: 400;
          // transition: all 0.2s ease-out;
          position: relative;
          cursor: pointer;
          display: inline;
          
          &:hover {
            color: var(--primary-n40);
          }

          &.active {
            color: var(--primary-n40);
            font-weight: 700;

            &:before {
              content: "";
              height: 6px;
              width: 6px;
              position: absolute;
              background: var(--tint-shade-b20);
              top: 9px;
              left: -11px;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .config-wrap {
      margin-top: 40px;
    }
    .accordion-wrapper{
      background: var(--tint-shade-b5--20);
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(125, 125, 125, 0.17);
      
      .sidebar-accordion{
        box-shadow: 0 1px 1px 0 rgba(34,36,38,.05), 0 0 0 1px rgba(34,36,38,.05);
        &:last-child{
          margin-top: 8px;
        }

        &.ui.accordion{
          .title{
            color: var(--primary-n40--70);
          }
        }

        .accordion-button{
          margin-top: 20px;
        }

        .ui.search{
          &>.results{
                position: relative;
                min-height: 56px;
                max-height: 116px;
                overflow-y: scroll;
                width: auto;
            .result{
              padding: 10px;
      
              .content{
                padding: 0;
              }
      
              .title{
                border: none;
              }
            }

            .message{
              margin: 0;
            }
          }

          &.error{
            .input{
              border-color: var(--tint-shade-r20);
            }
          }
        }
      }
    } 
  }
}
.proj-cont {
  display: flex;
  justify-content: space-between;
  
  .view-project-sec {
    margin-left: 20px;

    .view-proj {
      min-width: 116px;
      text-transform: uppercase;
    }
  }
}

// styling fourth field of currency field

.fourth-child{
  .field:nth-child(4){
    margin-top:28px!important;
  }
}
// styling text editor of form to be focused on click within textarea
.ck-editor__editable_inline{
  min-height: 108px
}

//  styling progress bar while uploading file
.progress{
  background: #ddd;
  width: 150px;
  display:block;
  border-radius:24px;
  margin-top: 20px;
  &--bar{
    height: 100%;
    background: blue;
    border-radius: 24px;
    color: var(--white);
    text-align: center;
    // width:1%;
    background: var(--tint-shade-b20);
  }
}

// adding icons to the download button for uploaded file
.project-info.file-download{
  
  p{
    word-break: break-all;
    position: relative;
    padding-left: 24px;

    span{
      margin-right:16px;
      color: var(--tint-shade-n20);
    }

    a{
    &:before{
      content:'';
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      left: 0;
      top: 0;
      background: url('../../../images/fileIcons/common.svg') no-repeat 0 50%;
      background-size: 16px;
    }
  }

  a[href$=".pdf"]   {
    &:before{
        background: url('../../../images/pdf.svg') no-repeat 0 50%;
      }
    }
    a[href$=".ppt"],
    a[href$=".pptx"],
    a[href$=".PPT"],
    a[href$=".PPTX"]  {
      &:before{
        background: url('../../../images/fileIcons/powerpoint.svg') no-repeat 0 50%;
        background-size: 16px;
      }
    }
    a[href$=".xls"],
    a[href$=".xlsx"],
    a[href$=".XLS"],
    a[href$=".XLSX"],
    a[href$=".csv"]  {
      &:before{
        background: url('../../../images/fileIcons/excel.svg') no-repeat 0 50%;
        background-size: 16px;
      }
    }
    a[href$=".jpg"],
    a[href$=".jpeg"],
    a[href$=".png"],
    a[href$=".JPG"],
    a[href$=".JPEG"],
    a[href$=".PNG"]{
      &:before{
        background: url('../../../images/fileIcons/image.svg') no-repeat 0 50%;
        background-size: 16px;
      }
    }
    a[href$=".doc"],
    a[href$=".docx"],
    a[href$=".DOC"],
    a[href$=".DOCX"]{
      &:before{
        background: url('../../../images/fileIcons/word.svg') no-repeat 0 50%;
        background-size: 16px;
      }
    }
  }
}
.counter{
  color: var(--tint-shade-n10);
  .time{
    margin-left:8px;
    color: var(--tint-shade-n20);
  }
}