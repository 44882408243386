.hero-container{
    max-width: 1015px;
    margin: 40px auto 150px;
    border-radius: 12px;
    background: var(--tint-shade-b20);
    color: var(--white);
    padding: 100px 80px 80px;
    position: relative;
    box-shadow: 0px 45px 60px -40px rgba(37, 100, 222, 0.8);

    .img-wrapper{
        margin-left: -195px;
        flex: 1;
        min-width: 630px;
        position: relative;

        .hero-chart-wrapper{
            position: relative;
            z-index: 10;
        }

        .hero-main, .hero-chart, .hero-grid, .hero-bars, .hero-table{
            position: absolute;
        }

        .hero-main{
            left: 0;
            right: 0;
            top: -10px;
            margin: auto;
        }

        .hero-bars{
            left: -15px;
            top: 38px;
        }

        .hero-grid{
            top: 204px;
            left: 86px;
        }

        .hero-chart{
            right: 15px;
            // top: 28px;
            top: 53px;
            z-index: 1;
        }        

        .hero-table{
            right: 48px;
            top: 160px;
        }
    }

    .heading-1{
        margin: 10px 0 20px;
    }

    .body-2{
        line-height: 1.4;
    }

    .hero-stats{
        color: var(--primary-n40);
        padding: 20px 0 30px 40px;
        background: var(--white);
        align-items: flex-end;
        border-radius: 12px;
        box-shadow: 0px 45px 40px -40px rgba(37, 100, 222, 0.26);
        width: 520px;
        margin-top: 10px;

        p{
            margin-bottom: 2px;
        }

        > div{
            margin-right: 25px;

            &:last-child{
                margin-right: 0;
            }
        }

        .label-2{
            color: var(--tint-shade-n20);
            text-transform: uppercase;
        }
    }

    .hero-title{
        max-width: 330px;
        margin-left: 40px;
    }
}

.page-title{
    text-align: center;
    font-size: 3rem;
}

.hero-video-container{
    max-width: 1015px;
    margin: 10px auto 70px;
    border-radius: 12px;
    background: var(--tint-shade-b20);
    color: var(--white);
    padding: 25px 20px 20px;
    position: relative;
    box-shadow: 0px 45px 60px -40px rgba(37, 100, 222, 0.8);
    .video{
        width: 100%;
        height: 500px;
        border-radius: 12px;
        // border-color:  rgba(37, 100, 222, 0.8);
    }
}
