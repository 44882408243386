.inner-contents{
	padding:40px;
	border-radius:8px;
	background: var(--white);
	margin-top:40px;
    color: var(--tint-shade-n20);
	width:80%;
	button{
		margin-right:8px;
	}
	.row{
		padding-top:0;
		&:not(:last-child){
			margin-bottom: 11px;
    		border-bottom: 1px solid var(--primary-b0);
		}
	}
	.form{
		.fields,
		.field{
			padding:0!important;
			margin:0 0 16px 0!important;
			&:last-child{
				margin-bottom:0!important;
			}
		}
	}
}
.width-8{
	width: 8%!important;
}