.btn-normal {
	border-radius: 50px;
	padding: 13px 20px;
	font-size: 14px;
	// font-size: 0.75rem;
	text-transform: uppercase;
	border: 1px solid transparent;
	cursor: pointer;	
	display:inline-flex;
	align-items:center;
	outline: none;

	&--medium {
		padding: 9px 14px;
		// border:1px solid var(--primary-n40);
	}
	&--br4 {
		border-radius: 4px;
	}

	&--small{
		padding: 4px 8px;
    	font-size: 12px;
	}
	.ui.secondary.loading.button{
		background-color: var(--primary-n40);
	}
}

.btn-rect {
	border-radius: 4px;
	padding: 15px 20px;
	font-size: 14px;
	// font-size: 0.75rem;
	text-transform: uppercase;
	border: 1px solid transparent;
	cursor: pointer;

	&--small{
		padding: 10px 20px;
	}
}

.btn-text {
	background: none;
	border: none;
	color: var(--tint-shade-b20);
}
.btn-dark {
	background: var(--primary-n40);
	color: var(--white);

	&:hover {
		color: var(--white);
	}
}

.btn-blue {
	background: var(--tint-shade-b20);
	color: var(--white);

	&:hover {
		color: var(--white);
	}	
}

.btn-red{
	background: var(--tint-shade-r20);
	color: var(--white);
}

.btn-shadow--red {
	box-shadow: 0px 14px 40px -10px #697588;

	&:hover {
		box-shadow: 0px 14px 40px -10px#2f2f33;
	}
}

.btn-shadow--dark {
	box-shadow: 0px 14px 40px -10px #697588;

	&:hover {
		box-shadow: 0px 14px 40px -10px#2f2f33;
	}
}

.btn-shadow--blue {
	box-shadow: 0px 14px 40px -10px #2564de;

	&:hover {
		box-shadow: 0px 14px 40px -10px#0635A5;
	}
}

.btn-transition {
	transition: all 0.2s ease-out;
}

.btn-export, .btn-empty {
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 9999;
	border: none;
	outline: none;
	cursor: pointer;
	display:inline-flex;
	align-items:center;
	i{
		transform: rotate(-90deg);
	}

}

.btn-empty{
	i{
		transform: none;
	}
}

.export{
	i{
		transform: rotate(-90deg);
	}
}

.export-add-button{
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 999;
	button{		
		margin-left:10px;
	}
}

.btn-add-program {
	position: fixed;
	bottom: 100px;
	right: 40px;
	z-index: 999;
	border: none;
	outline: none;
	cursor: pointer;
	display:inline-flex;
	align-items:center;
}

.btn-filter {
	position: fixed;
	bottom: 40px;
	left: 40px;
	z-index: 9999;
	border: none;
	outline: none;
	cursor: pointer;
	display:inline-flex;
	align-items:center;
}

.btn-operation {
	text-transform: uppercase;
	border-radius: 50px;
}
.btn-outline {
	padding: 4px 12px;
	border-radius: 50px;
	font-size: 14px;
	// font-size: 0.75rem;
	border: 1px solid;
	background-color: transparent;
	outline: none;
	cursor: pointer;	
	display:inline-flex;
	align-items:center;

	&--small {
		padding: 1px 8px 0;
		text-transform: uppercase;
		font-size: 12px;
		// font-size: 0.625rem;
	}

	&--blue {
		color: var(--primary-b20);
		border: 1px solid var(--primary-b20);
	}

	&--red {
		color: var(--tint-shade-r20);
		border: 1px solid var(--tint-shade-r20);
	}

	&:hover {
		&.btn-outline--blue {
			background-color: var(--primary-b20);
			color: var(--white);
			border-color: var(--primary-b20);
		}

		&.btn-outline--red {
			background-color: var(--tint-shade-r20);
			color: var(--white);
			border-color: var(--tint-shade-r20);
		}
	}
}

.btn-disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

// especial case
.btn-disabled-delete {
	border-color: rgba(#d63230, 0.4);
	color: rgba(#d63230, 0.4);
	cursor: not-allowed;

	&:hover {
		border-color: rgba(#d63230, 0.4);
		color: rgba(#d63230, 0.4);
	}
}
.btn-block{
		padding: 4px 12px 4px 27px;
        background: url(../../images/ic_block.svg) no-repeat center left 12px;
}
.btn-unblock{
	padding: 4px 12px 4px 27px;
	background: url(../../images/ic_activate.svg) no-repeat center left 12px;
}

.btn-login{
	padding: 13px 40px;
}

.btn-download {
	border: none;
	background: none;
}