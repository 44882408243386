.dashboard-header {
  margin: 10px 0 40px;
  .header-main {
    margin: 0;
    line-height: 1.1;
  }
}
.card {
  margin-left: 32px;
  position: relative;
  // height: 50px;
  min-width: 50px;

  .ui.inverted.dimmer {
    background-color: transparent;
  }

  &-label {
    font-size: 12px;
    // font-size: 0.625rem;
    text-transform: uppercase;
    color: rgba(46, 49, 54, 0.7);
  }

  &-info {
    font-size: 24px;
    // font-size: 1.5rem;
    font-weight: 700;
    color: rgba(46, 49, 54, 1);
    margin-bottom: 0;
    line-height: 24px;
  }
}

.project-cost {
  .card {
    margin-left: 20px;

    &-info {
      font-size: 18px;
      //   font-size: 1rem;
    }

    .currency {
      font-size: 14px;
      //   font-size: 0.75rem;
      font-weight: 400;
    }
  }
}

.empty {
  text-align: center;
  margin-top: 100px;
  img {
    width: 220px;
  }
  p {
    font-size: 18px;
    // font-size: 1rem;
    margin-top: 10px;
  }
}

.chart-container {
  display: flex;
  margin: 55px 0;

  .chart-info {
    font-size: 12px;
    color: var(--tint-shade-n10);
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      transform: rotate(45deg);
    }
  }

  // Nested to apply style to chart-wrapper with chart-container parent
  .chart-wrapper {
    cursor: pointer;
    position: relative;

    &.disable-action {
      pointer-events: none;
    }
  }
}
.chart-wrapper {
  background: #fff;
  // width: 422px;
  padding: 30px 25px 10px;
  margin-right: 40px;
  border-radius: 4px;
  box-shadow: 0px 45px 40px -40px rgba(37, 100, 222, 0.26);
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  flex: 1;
  min-height: 220px;

  &:hover {
    border: 1px solid var(--tint-shade-b10);
    box-shadow: 0px 40px 40px -40px rgba(37, 100, 222, 0.6);
  }

  &:last-child {
    margin: 0;
  }

  &.ledger-chart {
    margin-bottom: 80px;
  }

  .bar-chart-wrapper {
    width: 100%;
    height: 150px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 20px;
      background: #fff;
    }
  }

  .header {
    span {
      font-size: 12px;
      //   font-size: 0.625rem;
      font-weight: 400;
      color: var(--primary-n40--70);
    }
  }

  .no-result {
    text-align: center;
    line-height: 120px;
  }
}

.pie-chart-loader {
  position: relative;
  min-width: 150px;
}

.pie-chart-wrapper {
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-radius: 4px;
  border: 1px solid transparent;
  background: transparent;
  padding: 5px;

  &:hover {
    background: var(--white);
    border: 1px solid var(--tint-shade-b10);
    box-shadow: 0px 15px 40px -15px rgba(37, 100, 222, 0.4);
  }
}

.pie-chart-legend-wrapper {
  width: 100%;
  height: 500px;
  padding: 0 30px 20px;
  position: relative;
  max-width: 900px;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: 60px;
    height: 20px;
    background: #fff;
  }
}
@media only screen and (min-width: 1200px) {
  .ui.modal.chart-modal {
    // width: 95%;
    // margin: 0 0 0 -450px;
    width: calc(95% - 30px);
    left: 30px;
    top: 50px;
    margin: 0;
  }
}

@media print {
  .ui.dimmer.modals.active {
    position: relative;
    height: auto;
  }

  .modals.dimmer .ui.scrolling.modal.chart-modal {
    margin: 0 !important;
  }
}
.chart-modal {
  height: calc(100% - 100px);

  @media print {
    width: 100%;
    box-shadow: none !important;

    i {
      display: none;
    }
  }

  .bar-chart-header {
    padding: 20px 30px;
    box-shadow: 0px 8px 40px -8px rgba(37, 100, 222, 0.2);
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;

    @media print {
      box-shadow: none;
    }
  }

  .header {
    margin-bottom: 0;
  }

  .chart-wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0;

    @media print {
      border: none !important;
      box-shadow: none !important;
      overflow: visible;
    }

    .btn-outline {
      margin-left: 10px;
    }
  }

  .bar-chart-wrapper {
    min-height: 92% !important;
    max-width: 700px;
    padding: 0 30px 20px;

    &::after {
      bottom: 20px;
    }

    @media print {
      min-height: 850px !important;
    }
  }
}

svg {
  tspan {
    text-transform: capitalize;
  }
}
.user-filter-option {
  margin-top: 20px;

  > div {
    flex: 1;
    margin-right: 20px;
  }
}

.chart-search-wrapper {
  > div {
    margin-right: 40px;

    &:first-child {
      width: 37%;
      margin-right: 50px;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.chart-stat {
  margin-right: 12px;

  &-info {
    color: var(--primary-n40);
    font-size: 14px;
    // font-size: 0.75rem;
    margin: 0;
    font-weight: 700;

    .label {
      font-weight: 400;
    }
  }

  &-amount {
    font-size: 12px;
    // font-size: 0.625rem;
  }

  .indicator {
    display: block;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    margin-right: 4px;
    top: 7px;
    position: relative;

    &.draft {
      background: var(--draft);
    }
    &.submitted {
      background: var(--submitted);
    }
    &.verification {
      background: var(--verification);
    }
  }
}

.filter-tags {
  margin-top: 34px;
  > p {
    font-size: 14px;
    // font-size: 0.75rem;
    color: var(--tint-shade-n20);
    margin-bottom: 4px;
  }

  .tag {
    margin: 0 4px 4px 0;
  }
}
.bulk-operation {
  > P {
    font-size: 12px;
    // font-size: 0.625rem;
    color: var(--primary-n40);
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  button {
    margin-right: 8px;
    line-height: 1.4;
    text-transform: capitalize;

    &:last-child {
      margin: 0;
    }
  }
}

.map-container {
  margin-top: 30px;
  border-radius: 8px;
  background: var(--white);
  padding: 24px;

  .map-wrapper {
    border-radius: 8px;
    overflow: hidden;
  }
}

.btn-toggle-wrapper {
  margin-top: 45px;
  overflow: hidden;
  border-radius: 50px;

  .btn-toggle {
    padding: 15px 18px 15px 15px;
    border: none;
    background: var(--tint-shade-n10);
    text-transform: uppercase;
    color: var(--white);
    font-weight: 700;
    display: inline-flex;
    align-items: center;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &.active {
      background: var(--primary-n40);
    }

    &:not(.active):hover {
      background: var(--tint-shade-n20);
    }
  }
}

.feedback-info {
  max-width: 550px;
}
.map-popup {
  padding: 10px;
  margin-bottom: 10px;
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .location-info {
    display: flex;
    margin-top: 10px;
    .info-label {
      width: 45%;
      color: #3e3e3e;
      opacity: 0.7;
    }
    .info-text {
      width: 70%;
      color: #3e3e3e;
    }
  }
}
