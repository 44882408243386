.page-not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;

    color: var(--tint-shade-n20);

    .heading-1{
        max-width: 560px;
        color: var(--primary-n40);
        margin-bottom: 24px;

        &.ne{
            line-height: 1.4;
        }
    }
    .body-2{
        max-width: 460px;
        line-height: 1.4;
    }
}