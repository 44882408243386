h1,
h2,
h3,
h4,
h5 {
  // font-family: "Noto Sans", sans-serif;
  font-family: "Mukta", sans-serif;
}

button {
  // font-family: "Noto Sans", sans-serif;
  font-family: "Mukta", sans-serif;
}
.ui {
  &.menu {
    // font-family: "Noto Sans ", sans-serif;
    font-family: "Mukta", sans-serif;

    .item:before {
      content: none;
    }

    &.main-header {
      box-shadow: 0px 8px 40px -8px rgba(37, 100, 222, 0.2);

      @media print{
        box-shadow: none;
        margin-bottom: 0;
      }

      &.no-background{
        background: none;
        box-shadow: none;
      }
    }
  }

  &.header {
    // font-family: "Noto Sans", sans-serif;
    font-family: "Mukta", sans-serif;
  }

  &.button {
    // font-family: "Noto Sans", sans-serif;
    font-family: "Mukta", sans-serif;
  }

  &.dimmer{
    z-index: 99999;
  }

  .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url]{
    font-family: "Mukta", sans-serif;
  }

  // form label weight and color
  // .form{
  //   .field > label, .inline.fields .field > label{
  //       font-weight: normal;
  //       color: var(--primary-n40)
  //   }
  // }

  &.form {
    .disabled{
      &.field, &>label, &.field > label{
        opacity: 1;
      }

      .react-select{
        opacity: 0.45;
      }
    }
  } 
}

.ui.checkbox {
  min-height: 12px;
  line-height: 14px;
  min-width: 12px;

  label:before {
    width: 14px !important;
    height: 14px !important;
    border: 1px solid var(--primary-b20);
    border-radius: 2px;
  }

  input:checked {
    ~ label:before,
    &:focus ~ label:before {
      background: var(--primary-b20);
      border-color: var(--primary-b20);
    }
    &:focus ~ label:after,
    ~ label:after {
      font-size: 10px;
      // font-size: 0.625rem;
      width: 14px;
      height: 14px;
      color: var(--white);
      background-color: var(--primary-b20) !important;
      border-radius: 2px;
    }
  }
  input[type = "radio"]{
    &:focus ~ label:after,~ label:after{
      border-radius: 50%;
    }
  }
}


td [data-tooltip]:not([data-position])::after {
  left: -150%;
}