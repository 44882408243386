:root{

    --heading-1:40px;
    --heading-2:26px;

    --body-1:18px;
    --body-2:16px;

    --label-1:14px;
    --label-2:12px;

}
