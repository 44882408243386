.ui.pagination.menu {
  background: #ffffff;
  border-radius: 23px;
  padding: 0 30px;

  a.item {
    font-size: 14px;
    // font-size: 0.75rem;
    line-height: 20px;
    text-transform: uppercase;
    color: #475060;
    background-color: transparent;
    position: relative;
    z-index: 1;
    transition: all 255ms ease-in;
    outline: none;

    &:before {
      content: " ";
      transition: all 255ms ease-in;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      border-radius: 4px;
      z-index: -1;
      background-color: transparent;
    }
    &:hover {
      &:before {
        background: #cacfd7;
        box-shadow: none;
        transform: scale(0.8);
      }
    }
    &.active {
      font-weight: bold;
      color: #ffffff;
      &:before {
        background-color: #2564de;
        box-shadow: 0px 8px 40px -8px #2564de;
        transform: scaleY(1.2);
      }
    }

    &:first-child,
    &:nth-child(2),
    &:nth-last-child(2),
    &:last-child {
      font-size: 12px;
      //   font-size: 0.625rem;
      line-height: 18px;
    }
  }
}
