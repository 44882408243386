.project-detail-container {
  border-radius: 4px;
  margin-top: 20px;
  .ui.grid {
    padding: 20px 0;
    .row {
      padding-top: 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .project-section {
    margin-bottom: 8px;
    background: var(--white);
    padding: 40px;
    .project-info {
      font-weight: 700;
      &.file-download {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 0;
      }
    }
  }
  .general-array {
    margin-right: 6px;
    display: inline-block;
    &::after {
      content: ',';
    }
    &:last-child {
      &::after {
        content: '';
      }
    }
  }

  .project-detail-info {
    margin-bottom: 20px;

    .info {
      &-detail {
        p {
          color: var(--tint-shade-n10);
          margin-bottom: 0;
        }
        span {
          color: var(--tint-shade-n20);
        }
      }

      &-rank {
        background: var(--tint-shade-p40);
        color: var(--white);
        line-height: 1;
        border-radius: 50%;
        height: 23px;
        width: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        &.A- {
          background: var(--tint-shade-p30);
        }
        &.B {
          background: var(--tint-shade-b20);
        }
        &.B- {
          background: var(--tint-shade-b10);
        }
        &.C {
          background: var(--tint-shade-p10);
        }
        &.C- {
          background: var(--tint-shade-b5);
        }
        &.D {
          background: var(--tint-shade-t30);
        }
        &.D- {
          background: var(--tint-shade-t10);
        }
        &.E {
          background: var(--tint-shade-t5);
        }
      }

      &-gate {
        .gate-label {
          background: var(--tint-shade-n20);
        }
      }

      &-status {
        color: var(--white);
      }
    }
  }
}
.ui.table.project-table {
  border: none;
  table-layout: fixed;
  margin: 30px 0 60px;
  font-size: 14px;
  //   font-size: 0.75rem;
  line-height: 18px;
  color: rgba(46, 49, 54, 0.7);
  background: none;
  border-spacing: 0 2px;

  th {
    // padding: 0 0 10px 4px;
    color: rgba(29, 31, 34, 0.7);
    text-transform: uppercase;
    background: none;
    font-weight: 400;
    word-break: break-word;
    white-space: pre-line !important;
    font-size: 12px;
    // font-size: 0.625rem;
    border: none !important;

    .ui.checkbox {
      margin-left: 10px;
    }
  }

  td {
    padding: 14px 10px 8px 4px;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    vertical-align: top;
    position: relative;
    transition: all 0.2s ease;
    word-wrap: break-word;
    .ui.checkbox {
      margin-left: 10px;
    }
    &:first-child {
      border-left: 1px solid transparent;
      padding-left: 10px;
    }
    &:last-child {
      border-right: 1px solid transparent;
    }
  }

  tr {
    td {
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    cursor: pointer;
    &:first-child td {
      border-top: 1px solid transparent;
    }
    &:hover {
      td {
        border-color: #7ca7f8;

        .actions {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.selected-row {
      background: var(--tint-shade-b5);
    }
  }

  .link-row {
    display: table-row;

    .project-metadata,
    td {
      color: rgba(46, 49, 54, 0.7);
    }
    &:hover {
      td {
        border-color: #7ca7f8;

        .actions {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  // thead {
  //   .id-header {
  //     width: 4%;
  //   }
  // }

  tbody {
    background: var(--white);
  }

  .column-action {
    margin-bottom: 8px;
  }

  .actions {
    position: absolute;
    bottom: 2px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
    font-size: 0;
    transition: all 0.2s ease;
  }

  .project-id {
    color: rgba(46, 49, 54, 0.5);
    padding-left: 10px;
  }

  .project-title {
    font-size: 16px;
    // font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 2px;
    color: #2e3136;
    .blocked-user {
      color: #d63230;
      font-size: 12px !important;
      font-style: normal;
      font-weight: normal;
    }

    .icon-done {
      display: inline-flex;
      margin-right: 6px;
    }
  }

  .not-available {
    color: rgba(46, 49, 54, 0.4);
  }

  .ui.checkbox {
    top: 3px;
  }

  .table-bottom-spacing {
    padding-bottom: 28px;
  }
}

.project-metadata {
  font-size: 12px;
  // font-size: 0.625rem;

  .divider {
    display: inline-block;
    margin: 0 5px;
  }

  .project-status {
    background: #ee8282;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    padding: 2px 6px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 5px;

    &.draft {
      background-color: var(--draft);
    }

    &.submitted {
      background-color: var(--submitted);
    }

    &.verification {
      background-color: var(--verification);
    }

    &.dark {
      background-color: var(--tint-shade-n20);
    }
  }
}

.ui.table.no-action {
  tr {
    cursor: initial;
  }
}

.download-zip-button {
  position: fixed;
  bottom: 60px;
  right: 70px;
}

.actions {
  .btn-outline {
    background: var(--white);
    margin-left: 4px;
  }
}

@media print {
  body {
    background: #fff;
  }
  .no-print {
    display: none !important;
  }
  .project-detail-container {
    box-shadow: none;
    padding: 10px 0;
    margin-top: 0;
    .ui.grid {
      display: block !important;
      .row {
        page-break-inside: avoid !important;
      }
    }
  }

  .project-section {
    // page-break-after: always;
    padding: 10px 0 !important;
    margin-bottom: 40px !important;
  }
}

.sub-sector {
  list-style: disc;
  font-weight: 400;
  margin-top: -10px;
}
.notice-container {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(60, 87, 223, 0.1);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 20px;
  h3 {
    text-align: center;
    text-decoration: underline;
  }
  & + .header {
    margin-top: 8px;
  }
}
.notice-trigger {
  text-align: right;
  margin-bottom: 10px;

  &.open {
    float: right;
    position: relative;
    top: 10px;
    right: 10px;
  }

  & + .header {
    margin-top: 8px;
  }
}
.project-list-container {
  border-radius: 4px;
  padding-bottom: 30px;
  position: relative;
  margin-top: 30px;

  .ui.active.dimmer {
    min-height: 100px;
  }

  .filter-container {
    padding: 30px 30px 5px;
    .align-center {
      padding: 5px 0px;
    }
  }

  .advance-filter-container {
    position: absolute;
    background: #f0f0f0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    z-index: 10;
    top: 50px;
  }

  .filter-tags {
    .tag {
      border: 1px solid #5cb6ae;
      padding: 4px 10px;
      border-radius: 50px;
      opacity: 0.8;
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 4px;
      color: #5cb6ae;
    }

    .tag-label {
      color: #5cb6ae;
      margin-right: 5px;
    }
  }
}

.operations-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  .btn-outline {
    margin-left: 8px;
  }
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.ui.form .fields {
  .error {
    border-color: red;
    > label {
      color: red !important;
    }
  }
}
.error {
  label {
    color: red !important;
  }
}

.verified {
  background: #7be297 !important;
}
.not-verified {
  background: #ffabab !important;
}
.verification-form {
  margin-top: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
}
.ui.menu .nav-items {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  //   font-size: 0.6875rem;
  text-transform: uppercase;
  .nav-menu-items {
    padding: 0;
    margin: 0;
    font-size: 14px;
    // font-size: 0.75rem;
    height: 100%;
    &-secondary {
      font-size: 12px;
      //   font-size: 0.625rem;
      margin-right: 12px;

      .nav-menu-item {
        padding: 0;
        margin: 0 5px;

        &.active {
          &:after {
            height: 2px;
            bottom: -12px;
          }
        }
      }
    }
  }

  .nav-menu-item {
    padding: 0 7px;
    margin: 0 3px;
    position: relative;
    a {
      color: var(--primary-n40--70);
      display: inline-flex;
      height: 100%;
      align-items: center;
      &:hover {
        color: var(--primary-n40);
      }
    }

    span {
      color: var(--primary-n40--70);
      display: inline-flex;
      height: 100%;
      align-items: center;
      &:hover {
        color: var(--primary-n40);
      }
    }

    &.active {
      &:after {
        content: '';
        height: 5px;
        width: 100%;
        background: #2564de;
        position: absolute;
        // bottom: -34px;
        bottom: -12px;
        left: 0;
      }

      a {
        color: var(--primary-n40);
        font-weight: 700;
      }
    }
  }
  .parent-menu {
    background: url('./../images/dropdown.svg') no-repeat center right;
    padding-right: 8px;
    .child-menu {
      display: none;
      padding: 0;
      position: absolute;
      top: 90%;
      background-color: var(--white);
      z-index: 1;
      width: 190px;
      border-radius: 4px;
      box-shadow: 0px 8px 40px -8px rgba(37, 100, 222, 0.2);
      li {
        &:first-child {
          a {
            padding-top: 16px;
          }
        }
        &:last-child {
          a {
            padding-bottom: 16px;
          }
        }
        a {
          color: var(--primary-n40--70);
          font-weight: normal;
          font-size: 14px;
          //   font-size: 0.75rem;
          line-height: 20px;
          display: inline-block;
          width: 100%;
          padding: 8px 16px 8px;
        }
        &:hover a {
          color: var(--primary-n40);
        }
        &.active a {
          color: var(--primary-n40);
          font-weight: 700;
        }
      }
    }
    &:hover .child-menu {
      display: block;
    }
  }

  .add-project {
    font-size: 14px;
    // font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #2564de;
    color: #fff;
    border-radius: 50px;
    padding: 10px 12px;
    min-width: 160px;
    margin-right: 12px;
    box-shadow: 0px 6px 40px -8px rgba(37, 100, 222, 0.54);
    transition: all 0.3s ease-out;

    &:hover {
      background-color: #1453cd;
      color: #fff;
      box-shadow: 0px 6px 40px -8px rgba(37, 100, 222, 1);
    }
  }

  .user {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #b8cffc url('./../images/avatar.svg') no-repeat;
    position: relative;
    cursor: pointer;

    i.dropdown.icon {
      visibility: hidden;
    }

    .item {
      background: none !important;
    }

    &-details {
      display: none;
      background-color: var(--white);
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 1;
      border-radius: 4px;
      color: var(--primary-n40);
      box-shadow: 0px 8px 40px -8px rgba(37, 100, 222, 0.2);
      ul {
        margin: 0;
        padding: 00;
        li {
          white-space: nowrap;
          color: var(--primary-n40--70);
          font-weight: normal;
          font-size: 14px;
          //   font-size: 0.75rem;
          line-height: 20px;

          &:first-child {
            a {
              padding-top: 16px;
            }
          }

          &:last-child {
            padding: 8px 16px 16px;
          }
          &:hover {
            color: var(--primary-n40);
          }

          a {
            color: var(--primary-n40--70);
            display: inline-block;
            width: 100%;
            padding: 8px 16px 8px;
            &:hover {
              color: var(--primary-n40);
            }
          }
          &.active a {
            font-weight: bold;
            color: var(--primary-n40);
          }
        }
      }
    }

    &:hover .user-details {
      display: block;
    }
  }

  .dropdown.item .menu {
    top: calc(100% + 6px);
  }

  .language-select {
    cursor: pointer;
    // overflow: hidden;
    margin-right: 12px;
    position: relative;
    &-text {
      border: 1px solid var(--tint-shade-n5);
      border-radius: 50px;
      background: var(--white) url('./../images/dropdown.svg') no-repeat center right 7px;
    }
    .option {
      display: none;
      background-color: var(--white);
      border-radius: 4px;
      box-shadow: 0px 8px 40px -8px rgba(37, 100, 222, 0.2);
      position: absolute;
      top: 100%;
      width: 100%;
      .list {
        display: inline-flex;
        align-items: center;
        padding: 10px;
        width: 100%;
      }
    }
    &:hover .option {
      display: block;
    }
    .item {
      background: none !important;
    }

    .flag {
      padding: 7px 20px 7px 12px !important;
      // width: 80px;
      display: inline-flex;
      align-items: flex-end;
    }
    .en {
      &:before {
        content: '';
        background: url('./../images/ic_english.svg') no-repeat;
        display: block;
        height: 12px;
        width: 18px;
        margin-right: 4px;
      }
    }
    .ne {
      &:before {
        content: '';
        background: url('./../images/ic_nepal.svg') no-repeat;
        display: block;
        height: 14px;
        width: 9px;
        margin-right: 4px;
      }
    }
    button {
      background: none;
      border: none;
      text-transform: uppercase;
      color: #000;
      padding: 10px;
    }
  }

  .user {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #b8cffc url('./../images/avatar.svg') no-repeat;

    i.dropdown.icon {
      visibility: hidden;
    }

    .item {
      background: none !important;
    }
  }

  .dropdown.item .menu {
    top: calc(100% + 6px);
  }
}

.logo-wrapper {
  // min-width:240px;
  display: inline-block;
  .logo {
    height: 46px;
    display: inline-block;
    vertical-align: middle;
    &-text {
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
      p {
        margin: 0;
        font-size: 16px;
        // font-size: 0.875rem;
        font-weight: 700;
        color: var(--primary-n40);
        text-transform: uppercase;
        font-family: 'open sans';
        font-family: 'Noto Sans', sans-serif;
        font-family: 'Mukta', sans-serif;

        span {
          display: block;
          font-size: 13px;
          //   font-size: 0.5625rem;
          color: rgba(#697588, 0.7);
          letter-spacing: 0.3px;
          line-height: 100%;
        }
      }
    }
  }
}
