@font-face {
    font-family: "Material Icons";
    font-display: block;
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"),
    url('./../../fonts/MaterialIcons-Regular.woff2') format('woff2'),
      url('./../../fonts/MaterialIcons-Regular.woff') format("woff"),
       url('./../../fonts/MaterialIcons-Regular.ttf') format('truetype');
  }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 14px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-display: block;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

    /* Rules for sizing the icon. */
    &.md-10{
        font-size: 10px;
    }
    &.md-12{
        font-size: 12px;
    }
    &.md-14 {
        font-size: 14px; 
    }
    &.md-18 { 
        font-size: 18px; 
    }
    &.md-24 { 
        font-size: 24px; 
    }
    &.md-36 { 
        font-size: 36px; 
    }
    &.md-48 { 
        font-size: 48px; 
    }

    &.md-normal{
        font-family: 'Material Icons';
    }
    
    &.md-bold{
        font-weight:bold;
    }
    /* Rules for using icons as black on a light background. */
    &.md-dark {
        color: rgba(0, 0, 0, 0.54);
        &.md-inactive { 
            color: rgba(0, 0, 0, 0.26); 
        }
    }
    /* Rules for using icons as white on a dark background. */
    &.md-light { 
        color: rgba(255, 255, 255, 1);
        &.md-inactive { 
            color: rgba(255, 255, 255, 0.3); 
        }
    }
    &.md-blue{
        color: var(--primary-b20);
    }

    /* Custom Styling. */
    &.mr-6{
        margin-right: 6px;
    }
    &.mr-2{
        margin-right: 2px;
    }
    &.ml-6{
        margin-left:6px;
    }
    &.ml-2{
        margin-left:2px;
    }
    &.mt-6{
        margin-top:6px;
    }

    &.material-delete{
        width: 14px;
    }
    &.icon-image-preview{
        width: 14px;
    }
}
form{
    .form-search{
        position:relative;
        i{
            position:absolute;
            top:8px;
            left:10px;

        }
    }
    
}
// .chart-modal{
//     i{
//         position: absolute;
//         top: -20px;
//         right: -20px;
//         cursor:pointer;
//     }
// }




