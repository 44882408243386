.sidebar-filter {
  background-color: var(--primary-n40);
  position: fixed;
  width: 345px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  // overflow-y: auto;
  color: var(--white);
  transition: all 0.2s ease;

  &.hidden {
    left: -350px;
    transition: all 0.2s ease;
    visibility: hidden;

    .filter-container {
      .filter-action {
        left: -350px;
      }
    }
  }
  .close-button {
    // opacity: 0.6;
    cursor: pointer;
    position: absolute;
    top: 60px;
    right: -23px;
    background: #2a2f38;
    padding: 5px 6px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    i {
      // height: 20px;
      // width: 20px;
      // margin-left: 6px;
      margin-bottom: 2px;
      border-radius: 50%;
      display: block;
      text-align:center;
    }
    p {
      text-transform: uppercase;
      font-size: 12px;
      // font-size: 0.625rem;
      font-weight: bold;
    }
    // &:hover {
    //   opacity: 0.8;
    // }
  }
  .filter-container {
    padding: 40px 42px 180px;
    position: relative;
    height: 100%;
    overflow-y: auto;

    .close-button {
      opacity: 0.6;
      cursor: pointer;
      position: absolute;
      top: 60px;
      right: 40px;
      i {
        height: 20px;
        width: 20px;
        margin-left: 6px;
        margin-bottom: 2px;
        background-color: var(--white);
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      p {
        text-transform: uppercase;
        font-size: 12px;
        // font-size: 0.625rem;
        font-weight: bold;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    .filter-action {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 0 40px;
      background: linear-gradient(
        180deg,
        rgba(42, 47, 56, 0) 0%,
        rgba(42, 47, 56, 0.8) 21.24%,
        #2a2f38 100%
      );
      width: 345px;
      z-index: 100;
      transition: all 0.2s ease;

      .btn-text {
        color: var(--white);
        font-size: 12px;
        // font-size: 0.625rem;
        text-transform: uppercase;
      }

      button:not(:last-child) {
        margin-bottom: 20px;
      }

      &.visibility-hidden {
        transform: translateY(50px);
      }

      &.visibility-visible {
        transform: translateY(0);
      }
    }

    > .header {
      margin-bottom: 10px;
      padding: 0 !important;
      color: var(--white);
      cursor: default;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 14px 20px;
      .title {
        font-weight: bold;
        font-size: 14px;
        // font-size: 0.75rem;
        line-height: 20px;
        text-transform: uppercase;
        opacity: 0.5;
        margin-bottom: 0;
      }
      .collapse {
        font-size: 12px;
        // font-size: 0.625rem;
        line-height: 18px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
      }
    }

    .filter-section {
      background: var(--white);
      margin-bottom: 10px;
      border-radius: 4px;
      color: var(--primary-n40);

      &:hover {
        background: var(--primary-b0);
      }

      .title {
        opacity: 1;
        margin-bottom: 0;
        text-transform: capitalize;
      }
      .contents {
        padding: 0 20px 14px;
        & > input {
          border: 1px solid var(--primary-b20);
          box-sizing: border-box;
          border-radius: 4px;
          font-size: 12px;
          //   font-size: 0.625rem;
          color: var(--primary-n40);
          width: 100%;
          margin-bottom: 19px;
          margin-top: 6px;
          padding: 12px;
        }
        .scrollarea {
          max-height: 146px;
          .scrollbar-container.vertical {
            width: 2px;
            background-color: var(--tint-shade-n5);
            border-radius: 2px;
            opacity: 1 !important;
            .scrollbar {
              right: 0;
              width: 2px;
              background-color: var(--primary-b20);
              border-radius: 2px;
              opacity: 1 !important;
              // height: 60px !important;
            }
          }
        }
        label {
          display: block;
          font-size: 14px;
          //   font-size: 0.75rem;
          line-height: 20px;
          color: var(--primary-n40);
          position: relative;
          padding: 0 8px 0 20px;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
          input {
            margin-top: 5px;
            display: none;
          }
          .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 12px;
            width: 12px;
            background: var(--white);
            border: 1px solid var(--primary-b20);
            box-sizing: border-box;
            border-radius: 2px;
          }
          input:checked ~ .checkmark {
            background-color: var(--primary-b20);
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            left: 3px;
            top: 0px;
            width: 3.5px;
            height: 6.5px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }

          &.radio-btn {
            .checkmark {
              height: 14px;
              width: 14px;
              border-radius: 50%;
            }
            input:checked ~ .checkmark {
              background-color: var(--white);
            }
            .checkmark:after {
              left: 3px;
              top: 3px;
              width: 6px;
              height: 6px;
              background: var(--primary-b20);
              border-radius: 50%;
            }
          }
        }
        &.hide {
          display: none;
        }

        .option-form {
          margin-top: 6px;
          label {
            margin-bottom: 2px;
            padding: 0;
          }
        }

        .options-multiple{
          margin-bottom: 10px;
          
          .option-title{
            margin-bottom: 4px;
            font-size: 12px;
            font-weight: 700;
          }
        }

        .filter-label-inline{
            display: inline-block;
        }

        .filter-ranking{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 50px 50px 50px;
          grid-template-areas: "c b a"
                                "c- b- a-"
                                "e d- d";
          
          label{
            width: 100%;
            height: 100%;
            color: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            .checkmark{
              background: none;
              border: none;

              &:after{
                width: 6px;
                height: 12px;
                border-width: 0 2px 2px 0;
                left: 10px;
                top: 0;
                border-color: inherit;
              }
            }

            input:checked ~ .checkmark{
              background: none;
            }
          }
        }
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: url("../../images/ic_calendar.svg") no-repeat;
      width: 15px;
      height: 18px;
     color: rgba(0, 0, 0, 0);
       opacity: 1;
      border-width: thin;
   }
  }

  .A{
    background: var(--tint-shade-p40);
    color: var(--white);
    grid-area: a;
  }
  .A-{
    background: var(--tint-shade-p30);
    color: var(--white);
    grid-area: a-;
  }
  .B{
    background: var(--tint-shade-b20);
    color: var(--white);
    grid-area: b;
  }
  .B-{
    background: var(--tint-shade-b10);
    grid-area: b-;
  }
  .C{
    background: var(--tint-shade-p10);
    grid-area: c;
  }
  .C-{
    background: var(--tint-shade-b5);
    grid-area: c-;
  }
  .D{
    background: var(--tint-shade-t30);
    color: var(--white);
    grid-area: d;
  }
  .D-{
    background: var(--tint-shade-t10);
    grid-area: d-;
  }
  .E{
    background: var(--tint-shade-t5);
    grid-area: e;
  }
}