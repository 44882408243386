.form-container {
  padding: 40px !important;

  @media print{
    padding: 20px 40px !important;

    .header.large{
      margin-top: 0;
    }
  }
}

.form-margin-bottom {
  margin-bottom: 20px;
}

.form-section {
  margin-bottom: 40px;
}

.conditional-radio-label {
  width: 40%;
}

.ui {
  &.dropdown .menu {
    right: 0;
    left: unset;
  }

  &.menu {
    margin-top: 0;
    box-shadow: none;
    border: none;
  }

  &.form {
    .field > label,
    .inline.fields .field > label {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.ui.modal {
  top: 100px;
}

.ui.form {
  input[type='checkbox'] {
    vertical-align: unset;
  }

  .date-picker {
    align-items: flex-end;

    &-nepali {
      min-width: 270px;

      .input-calendar {
        display: block;
      }

      .input-calendar-wrapper {
        right: 0;
      }
    }
  }

  .section-summary {
    align-items: flex-end;
  }

  .fields {
    &.sub-sector-container {
      background: #eee;
      padding-left: 40px;
      padding: 10px 0 2px 20px;
      margin-top: -10px;
      flex-wrap: wrap;

      .field {
        flex-basis: 33%;
        margin-bottom: 10px;
      }
    }
  }

  .ui.input {
    border: none;
  }
}

.ui .input-upload {
  position: relative;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
  z-index: 0;
  cursor: pointer;

  .input-file {
    position: absolute;
    opacity: 0 !important;
    top: 0;
    left: 0;
  }

  button {
    width: 100%;
    height: 40px;
    position: relative;
    z-index: -1;
  }
}

.add-project-form {
  margin-top: 60px;
  background: #fff;
  padding: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  > .header {
    margin-bottom: 30px;
  }

  > p {
    color: rgba(0, 0, 0, 0.7);
  }
}

.project-form {
  &-title {
    margin: 20px 0 10px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 24px;
      // font-size: 1.5rem;
      margin: 0;
      max-width: 630px;
    }

    .project-status-select {
      // width: 180px;
      display: flex;
      align-items: center;

      .project-status {
        width: 180px;
        margin-left: 10px;
      }
    }
  }

  .form-container {
    padding: 0 40px 40px 0 !important;

    .ui.form {
      padding: 30px;
      background-color: #fff;
    }
  }
}

.form-sidebar-container {
  padding: 40px 0;
}

.form-sidebar {
  position: sticky;
  top: 30px;
  max-width: 260px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding: 15px 20px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.1s ease-in;
    cursor: pointer;

    &:hover {
      background-color: rgba(#4066ed, 0.2);
    }

    &.active {
      background-color: #4066ed;
      color: #fff;
    }
  }
}

.delete-button {
  align-self: flex-end;
}

.create-new-program {
  align-self: flex-end;
  padding-bottom: 8px;
}

.btn-link {
  border: none;
  text-decoration: underline;
  color: var(--tint-shade-b20);
  cursor: pointer;
  background: none;
  outline: none;

  &:hover {
    color: var(--tint-shade-b30);
  }
}

.ui.header {
  &.repeating-field-heading {
    margin-top: 45px;
  }
}

// .repeating-field-button-container {
//     display: flex;
//     justify-content: flex-end;
//     padding-right: 75px;
// }

.success-msg-container {
  display: flex;
  align-items: flex-start;
}

.animated-progress-bar {
  width: 50px;
  margin-right: 20px;
}

.repeating-field-container {
  margin-bottom: 1em;
}

.field-flex-wrap {
  flex-wrap: wrap;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.header-required {
  &::after {
    content: '*';
    color: #db2828;
    margin-left: 2px;
  }
}

.options-info {
  position: absolute;
  top: 90px;
  right: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 300px;
  border-radius: 4px;
}

[type='checkbox'],
[type='radio'] {
  margin-right: 4px;
}

.ui.form .custom-divider:first-of-type {
  border: none;
}

.custom-divider {
  // margin-top: 20px;
  // padding-top: 10px;

  margin-top: 0;
  padding: 10px 0;

  &:not(.first) {
    padding: 10px;
    margin-bottom: 10px;
    background: #f3f3f3;
  }
}

.project-feedback-form {
  position: fixed;
  top: 50%;
  right: 0;
  width: 300px;
  transform: translate(300px, -50%);
  transition: transform 0.3s ease-in;
  height: 100%;
  display: flex;
  align-items: center;
  // box-shadow: 0 2px 8px rgba(0,0,0,0.1);

  &.open {
    transform: translate(0, -50%);
  }

  .feedback-form-container {
    padding: 20px;
    background: #ededed;
    border-radius: 4px;
    width: 100%;
  }

  .btn-feedback {
    position: absolute;
    left: -64px;
    background: #4066ed;
    padding: 10px 15px;
    color: #fff;
    // border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 50%;
    width: 90px;
    cursor: pointer;
    transform: rotate(90deg);
    transform-origin: center;
  }
}

.notes-list {
  list-style: disc;

  li {
    line-height: 22px;
    margin-bottom: 10px;
  }
}

.agency-search-result i.icon.search:before {
  content: none;
}

.validation-error {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.delete-file-field {
  label {
    display: inline-block;
    vertical-align: top;

    &.header-style {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 700;
      text-transform: none;
    }
  }

  button {
    display: inline-block;
    vertical-align: top;
  }
}
.break-all {
  word-break: break-all;
}

.file-upload-border {
  border: 1px solid #22242626;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}

.delete-file-btn {
  padding: 5px 5px 5px 10px !important;
}

.field-vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.field-align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.operation-wrapper {
  position: relative;

  .operation-button {
    display: flex;
  }

  .operation-main {
    display: inline-block;
    background: #eee;

    a {
      padding: 8px 10px;
      display: inline-block;
    }
  }

  .operation-select {
    display: inline-block;
    background-color: #dadada;
    padding: 8px 5px;
    cursor: pointer;
  }

  .operation-options {
    position: absolute;
    top: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 95px;
    background: #fff;
    z-index: 10;

    a,
    span {
      display: block;
      padding: 8px 10px;
      color: #4183c4;
      cursor: pointer;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}

// New form style
.ui.form.form-new {
  // semantic UI style overwrite
  .field {
    > label {
      font-weight: 400;
      color: var(--tint-shade-n20);
      font-size: 16px;
      margin-bottom: 8px;
    }
    > .selection.dropdown {
      border: 1px solid var(--primary-b20);
      font-size: 16px;
      min-width: auto;
    }
    .ui.selection.active.dropdown .menu {
      border-color: var(--primary-b20);
    }

    &.error {
      input {
        border-color: #e0b4b4 !important;
      }

      .labeled {
        .ui.label:not(.dropdown) {
          background: var(--tint-shade-r5);
        }
      }
    }

    .tooltip {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px 16px 0 -10px;
      font-size: 10px;
      font-weight: 500;
      line-height: 0;
      background: rgba(184, 207, 252, 0.5);
      cursor: default;
    }

    .helper-text {
      margin-top: 10px;

      .btn-text {
        padding: 0;
      }
    }

    .labeled {
      .ui.label:not(.dropdown) {
        background: var(--tint-shade-b20);
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .field-helper-text {
    margin-top: -20px;
  }

  .inline.fields {
    margin-bottom: 0;
    align-items: flex-start;

    .field {
      padding-right: 16px;
      & > label {
        font-weight: 400;
        font-size: 14px;
      }

      & > .selection.dropdown {
        width: 100%;
      }
    }
  }

  .fields {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    &.sub-sector-container {
      background: var(--tint-shade-b5--20);
      margin-bottom: 10px;
    }
  }

  .field-repeat {
    background: var(--tint-shade-b5--20);
    padding: 10px;
    position: relative;
    margin-bottom: 8px;
    border-radius: 4px;

    &--first {
      margin-bottom: 30px;
    }

    .fields {
      margin-bottom: 10px;
    }

    &-btn {
      margin-top: -20px;
    }

    & + .field-repeat-btn {
      margin-top: 0px;
    }

    .btn-remove {
      position: absolute;
      top: 6px;
      right: 6px;
      visibility: hidden;
      background: #fff;

      &:hover {
        background: var(--tint-shade-r20);
      }
    }

    &:hover {
      .btn-remove {
        visibility: visible;
      }
    }
  }

  input {
    font-size: 16px;
    border: 1px solid var(--primary-b20) !important;

    &[type='radio'] {
      ~ label {
        line-height: 1.2;
      }
    }
  }

  .react-select {
    input {
      border: none !important;
      padding: 0.378571em 0 !important;
    }

    > [class*='control'] {
      border: 1px solid var(--primary-b20) !important;
      outline: none !important;
    }

    &.disabled {
      > [class*='control'] {
        background: none;
        opacity: 0.45;

        [class*='singleValue'] {
          color: hsl(0, 0%, 20%);
        }
      }
    }
  }

  .ui.checkbox label {
    margin-bottom: 8px;
    padding-left: 18px;
    line-height: 1.2;
  }

  // editor style
  .ck.ck-editor {
    border: 1px solid var(--tint-shade-b20);
    border-radius: 4px;
    min-height: 150px;
    ul {
      list-style: disc;
    }

    .ck-toolbar {
      border: 1px solid transparent;
      border-bottom: 1px solid #d8d8d8 !important;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
    }
    .ck-content {
      border: 1px solid transparent !important;
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }

  .form-group-assessment {
    .ck.ck-editor {
      background: var(--white);
    }

    .form-field__upload-btn {
      label {
        background: var(--white);
      }
    }
  }

  // Upload field
  .form-field {
    &__upload-btn {
      position: relative;

      .input-file {
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
      }
    }

    &__upload-files {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .file-item {
        width: 33.33%;
        padding: 0 5px;
        margin-bottom: 10px;

        .file-name {
          word-break: break-all;
          // &::before {
          //   content: '';
          //   width: 20px;
          //   height: 20px;
          //   background: url('../images/pdf.svg') no-repeat;
          //   display: inline-block;
          //   vertical-align: bottom;
          //   margin-right: 8px;
          // }
          span {
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
              display: inline-block;
              vertical-align: bottom;
            }
          }
        }

        & > div {
          background: var(--tint-shade-b5--20);
          padding: 5px;
          position: relative;
          border-radius: 4px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .btn-remove {
          position: absolute;
          top: 10px;
          right: 10px;
          visibility: hidden;
          background: #fff;

          &:hover {
            background: var(--tint-shade-r20);
          }
        }

        &:hover {
          .btn-remove {
            visibility: visible;
          }
        }
      }
    }
  }

  .form-actions {
    margin-top: 25px;

    [class^='btn'] {
      font-size: 16px;
    }
    .btn-rect {
      text-transform: none;
      margin-right: 12px;
    }
  }

  // .btn-link {
  //   margin-top: 10px;
  // }

  .ic-calendar {
    position: absolute;
    bottom: 5px;
    right: 10px;
    // z-index: 10;
    pointer-events: none;

    svg {
      fill: var(--primary-b20);
    }
  }
  .ic-remove-date {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 36px;
    bottom: 18px;
    cursor: pointer;
    &:hover {
    width: 14px;
    height: 14px;
    // right: 38px;
    bottom: 14px;
    }
  }

  .error {
    .ic-calendar svg {
      fill: var(--tint-shade-r20);
    }
  }

  .material-calendar {
    color: var(--primary-b20);
  }
  .react-datepicker-wrapper {
    width: 100%;

    input {
      background: transparent;
      cursor: pointer;
    }
  }

  .input-with-select {
    .dropdown {
      text-transform: uppercase;
      line-height: 1.4;
      background: var(--tint-shade-b20);
      color: #fff;

      .menu .item {
        text-transform: uppercase;
      }
    }

    &.error {
      .dropdown.icon {
        background: #fff6f6;
        color: #9f3a38;
      }

      input {
        border-color: #e0b4b4 !important;
      }
    }
  }
}

.form-basic__container {
  max-width: 700px;
  margin: 80px auto;

  .heading-1 {
    margin-bottom: 20px;
  }

  .form-actions {
    flex-direction: column;
    align-items: center;

    .btn-rect {
      margin: 0 0 25px 0;
    }

    .btn-text {
      color: var(--tint-shade-b20);
    }
  }
}

.form-group-container {
  margin-bottom: 8px;
  background: var(--white);
  padding: 30px;
  border-radius: 4px;

  .form-info {
    font-size: 18px;
    color: var(--primary-n40);
    margin-bottom: 30px;
    font-weight: 500;
  }

  &.form-group-assessment {
    background: var(--tint-shade-g0);
    border: 1px solid #b4e49e;
  }

  .form-group-inner-container {
    padding: 15px;
    background: var(--tint-shade-g0);
    margin-bottom: 20px;
  }

  .form-group-inner-title label {
    font-weight: 700 !important;
  }
}
